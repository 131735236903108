@font-face {
  font-family: HelveticaNeue;
  src: url(./IBMPlexSans-Regular.ttf);
}
* {
  font-family: HelveticaNeue;
}
body {
  font-size: 14px;
}
.form-main {
  background: #e2e2e2;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.form {
  width: 700px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  border-bottom: 2px solid #ccc;
  display: table;
}

.form.content {
  display: table-cell;
  vertical-align: middle;

}

.bg-navbar {
  background-color: #FBFBFB !important;
  box-shadow: 0 3px 2px -2px gray;
  height: 70px;
  font-size: 16px;
}

.nav-link-color {
  color: #A6A6A6 !important;
}

/* .nav-link-color:hover {
  color: #595959 !important;
} */

.nav-link-color.active {
  color: #595959 !important;
}
.navbar-brand {
  margin-left: -22px;
}
.btn-primary {
  background-color: #fff;
  color: #2F6BAF;
  border: 1px solid #2F6BAF;
  outline: none;
  border-radius: 20px;
  box-shadow: none;
  /* padding: 1px 24px 1px 24px; */
  padding-left: 24px;
  padding-right: 24px;
}
.nav-item {
  padding-left: -40px;
  padding-right: 80px;
  margin-left: -24px;
}
.left-nav {
  padding-left: 100px!important;
}
.btn-primary:hover {
  background-color: #2F6BAF !important;
  color: #fff;
  border: 1px solid #2F6BAF !important;
  outline: none;
}

.inch-left {
  margin-left: -48px;
}

.inch-right {
  margin-left: 48px;
}

/* Tooltip */
.custom_tooltip {
  position: relative;
  display: inline-block;
}

.custom_tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.custom_tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.custom_tooltip:hover .tooltiptext {
  visibility: visible;
}

.custom-margin-form-control {
  margin-left:  -50px;
}
.black {
  background: #fff!important;
  color: #fff!important;
}
.container {
  margin-left: 140px!important;
  margin-right: 51px!important;
  /* margin-right: auto; */
  width: 80%;
  display: block;
}

.theme-p-color {
  color: #2F6BAF;
  font-weight: bold;
}
.logout {
  color: #2F6BAF;
  border: 1px solid #2F6BAF;
  border-radius: 20px;
}

/* .reg {
  width: 500px;
} */
.react-tel-input  {

  margin-left: -50px;

}
.react-tel-input input {
  width: 100%!important;
  /* margin-left: -50px; */
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 42px!important;
}
@media screen and (max-width: 1368px) {
  .custom-margin-form-control {
    margin-left:  -30px;
  }
  .react-tel-input  {
    margin-left: -30px;
  }
}
@media screen and (max-width: 968px) {
  .react-tel-input  {
    margin-left: 0px;
  }
  .custom-margin-form-control {
    margin-left: 0px;
  }
  .container {
    box-sizing: border-box;
    margin-left: 0px!important;
    margin-right: 0px!important;
    width: 100%;
  }
  .nav-item {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  .left-nav {
    padding-left: 0px!important;
  }
}
