.circle-wrap {
    width: 80px;
    height: 80px;
    background: #cdcbd0;
    border-radius: 50%;
    border: 1px solid #cdcbd0;
  }
  
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 50%;
  }
  
  .circle-wrap .circle .mask {
    clip: rect(0px, 100px, 100px, 50px);
  }
  
  .circle-wrap .inside-circle {
    width: 64.96px;
    height: 64.96px;
    border-radius: 50%;
    background: #fff;
    line-height: 60px;
    text-align: center;
    margin-top: 8px;
    margin-left: 8px;
    color: #2F6BAF;
    position: absolute;
    z-index: 100;
    font-weight: 700;
    font-size: 1.5em;
  }
  
  /* color animation */
  
  /* 3rd progress bar */
  .mask .fill {
    clip: rect(0px, 50px, 100px, 0px);
    background-color: #2F6BAF;
  }
  
  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(135deg);
  }
  
  @keyframes fill{
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  